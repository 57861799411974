import axios from 'axios'
import { AUTH_LOGIN } from './actionTypes'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export function authLogin (token) {
  const request = axios.get(BACKEND_URL + '/login?id=' + token)
  return {
    type: AUTH_LOGIN,
    payload: request
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from "react-router-dom";
import ReactLoading from 'react-loading'
import Loadable from 'react-loadable'

import packageJson from '../package.json'

import { faUndo, faPlusSquare, faMinusSquare, faCamera } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import CacheBuster from './components/common/cacheBuster'
import { authLogin } from './actions/authLogin'

import './App.css'

library.add(faUndo, faPlusSquare, faMinusSquare, faCamera)

const FollowUpTasksComponent = Loadable({
  loader: () => import(/* webpackChunkName: '_ch_follow_up_tasks' */ './components/followUpTasksComponent'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

const FollowUpTasksTicketsComponent = Loadable({
  loader: () => import(/* webpackChunkName: '_ch_follow_up_tasks' */ './components/followUpTasksTicket'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: packageJson.version || 'ERROR',
      containerType: 'container',
    }

    let loginToken
    if (REDIRECT_URL) {
      try {
        if (process.env.REACT_APP_TOKEN_OVERRIDE) {
          loginToken = process.env.REACT_APP_TOKEN_OVERRIDE
        } else {
          loginToken = this.props.location.hash.split('=')[1].split('&')[0]
        }
      } catch (ex) {
        console.error("Can't find loginToken on pathname:", ex)
        window.location = REDIRECT_URL
      }
      this.props.authLogin(loginToken).then(this.handleResponse, this.handleError)
    } else {
      console.error("Can't find enviroment variables!")
    }
  }

  handleResponse(response) {
    if (!response.value.data.success) {
      window.location = REDIRECT_URL
    }
  }

  handleError(err) {
    console.error('Error de Login', err)
    window.location = REDIRECT_URL
  }


  componentDidMount() {
    console.log('Version:', this.state.version)
    let windowSearch = window.location.search
    if (windowSearch.search(/container-fluid=1/i) >= 0 || windowSearch.search(/container-fluid=true/i) >= 0) {
      this.setState({ containerType: 'container-fluid' })
    }
  }

  render() {
    return this.props.isAuthenticated ? (
      <div>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              return (
                <div>
                  <div className="alert alert-warning" align="center" role="alert" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); refreshCacheAndReload(); }}>
                    Estás usando una versión antigua! Haz click <a className="alert-link"><b>aquí</b></a> para actualizar a la última versión!
                  </div>
                </div>
              )
            }

            return (
              <div>
                {/*console.log("Bundle version - ", global.appVersion)*/}
              </div>
            );
          }}
        </CacheBuster>
        <Switch>
          <Route path='/' >
            <div className={this.state.containerType}>
              <div className='dr-page'>
                <Route exact path='/follow-up-tasks' component={FollowUpTasksComponent} />
                <Route exact path='/follow-up-tickets' component={FollowUpTasksTicketsComponent} />
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    ) : (
        <div className='container centered'>
          <ReactLoading type='spinningBubbles' color='#444' delay={0} />
        </div>
      )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.userProfile.isAuthenticated
  }
}

const mapDispatchToProps = {
  authLogin
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)
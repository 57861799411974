import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import loginReducer from './reducer_auth_login'
import gridReducer from './gridReducer'
import formIndexReducer from './formIndexReducer'
import dashboardReducer from './dashboardReducer'

const rootReducer = combineReducers({
  userProfile: loginReducer,
  form: formReducer,
  grids: gridReducer,
  formIndex: formIndexReducer,
  dashboardStatus: dashboardReducer
})

export default rootReducer
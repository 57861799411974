export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_FULFILLED = 'AUTH_LOGIN_FULFILLED'
export const AUTH_LOGIN_REJECTED = 'AUTH_LOGIN_REJECTED'

export const EXECUTE_QUERY = 'EXECUTE_QUERY'
export const EXECUTE_QUERY_API = 'EXECUTE_QUERY_API'
export const FILTER_QUERY = 'FILTER_QUERY'

export const CHANGE_GRID_STATUS = 'CHANGE_GRID_STATUS'
export const CLEAN_FILTER_IN_GRID = 'CLEAN_FILTER_IN_GRID'
export const CLEAN_GRID_CLICKS = 'CLEAN_GRID_CLICKS'
export const REMOVE_GRID = 'REMOVE_GRID'

export const FETCH_MANUFACTURER_DIMENSIONS = 'FETCH_MANUFACTURER_DIMENSIONS'
export const FETCH_MANUFACTURER_PARAMETERS = 'FETCH_MANUFACTURER_PARAMETERS'

export const PUSH_DYNAMIC_FILTERS = 'PUSH_DYNAMIC_FILTERS'
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS'
export const SET_FORM_INITIAL_VALUES = 'SET_FORM_INITIAL_VALUES'

export const CHANGE_PARAM_VALUE = 'CHANGE_PARAM_VALUE'
export const CHANGE_FILTER_FORM_STATUS = 'CHANGE_FILTER_FORM_STATUS'
export const APPLY_ALL_CHANGES = 'APPLY_ALL_CHANGES'

export const FOLLOW_UP_TASK_UPDATE = 'FOLLOW_UP_TASK_UPDATE'
export const FOLLOW_UP_TASK_UPDATE_MULTI = 'FOLLOW_UP_TASK_UPDATE_MULTI'
export const FOLLOW_UP_TICKET_UPDATE_MULTI = 'FOLLOW_UP_TICKET_UPDATE_MULTI'
import { CHANGE_GRID_STATUS, CLEAN_GRID_CLICKS, CLEAN_FILTER_IN_GRID } from "../actions/actionTypes";


export default function(state = {}, action) {
  if (action && action.gridSelectedRows && action.gridSelectedRows.length > 1)
    {}
  switch (action.type){
    case CHANGE_GRID_STATUS: 
      let grid = {}
      grid[action.gridName] = { 
        gridDimension: action.gridDimension, 
        gridSelectedRows: action.gridSelectedRows, 
        gridWasClicked: action.gridWasClicked,
        gridLevel: action.gridLevel
      }
      return  Object.assign({}, state, grid)
    
    case CLEAN_GRID_CLICKS:
      let st = {...state}
      for (let key in st){
        st[key].gridWasClicked = false
      }
      return st 
    case CLEAN_FILTER_IN_GRID:
      let cleanedGrid = {}
      cleanedGrid[action.gridName] = {
        gridDimension: action.gridDimension, 
        gridSelectedRows: action.gridSelectedRows, 
        gridWasClicked: action.gridWasClicked,
        gridLevel: action.gridLevel,
        gridWasRefreshed: action.gridWasRefreshed,
      }
      return Object.assign({}, state, cleanedGrid)
    
    default:
      return state
  }
}
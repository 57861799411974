import {
  SET_FORM_INITIAL_VALUES,
} from "../actions/actionTypes";

export default function(state = [], action) { 
  switch (action.type) {
    case SET_FORM_INITIAL_VALUES:
      return Object.assign({}, state, { initialValues: action.initialValues })
    default:
      return state;
  }
}

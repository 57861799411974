import initialState from './initial_state';
import * as types from '../actions/actionTypes';

export default function (state = initialState.userProfile, action) {
  switch (action.type) {
    case types.AUTH_LOGIN_FULFILLED:
      return {
        user: action.payload.data.user,
        isAuthenticated: action.payload.data.success,
        token: action.payload.data.token,
        message: action.payload.data.message
      };
    case types.AUTH_LOGIN_REJECTED:
      return { message: action.payload.message };
    case types.AUTH_LOGIN:
      return state;
    default:
      return state;
  }
}

//AUTH_LOGIN_FULFILLED responde en token existoso, token rechazado y 404
//AUTH_LOGIN_REJECTED responde en "network error"

import {
  CHANGE_PARAM_VALUE, CHANGE_FILTER_FORM_STATUS, APPLY_ALL_CHANGES
} from "../actions/actionTypes";

export default function (state = [], action) {
  switch (action.type) {
    case CHANGE_PARAM_VALUE:
      return Object.assign({}, state, { [action.payload.paramName]: action.payload.paramValue })
    case CHANGE_FILTER_FORM_STATUS:
      return Object.assign({}, state, { formChanged: action.payload })
    case APPLY_ALL_CHANGES:
      return Object.assign({}, state, { ...action.changes, waitAllChanges: action.waitAllFilters })
    default:
      return state;
  }
}
